import classNames from 'classnames';
import { SkeletonLine } from '@crazyegginc/hatch';

import { round } from '/src/utils/math';

import { ReactComponent as UpIcon } from '@crazyegginc/hatch/dist/images/icon-trend.svg';

export function TrendMetric({
  className,
  text,
  extraText,
  value,
  oldValue,
  below = false,
  loading,
  inverseColor = false,
  formatFn = (x) => Number(x).toLocaleString(),
}) {
  const change = round((value / oldValue - 1) * 100, 1);
  const isNegative = change < 0;

  return (
    <div
      className={classNames(
        'flex w-1 grow flex-col justify-between rounded border border-mystic-500 p-3 shadow',
        className,
      )}
    >
      <div className="text-header-4 text-center leading-none">{text}</div>
      <div
        className={classNames('flex w-full items-center justify-center', {
          'flex-col': below,
        })}
      >
        {loading ? (
          <div className={classNames('flex items-center', { 'h-[60px]': !below, 'h-[84px]': below })}>
            <SkeletonLine className="h-[40px] w-8 !rounded-md" />
          </div>
        ) : (
          <>
            <span
              className={classNames({
                'text-stats-number': formatFn(value).length <= 5,
                'text-[30px] text-black-pearl-500 font-semibold leading-[60px]': formatFn(value).length > 5,
              })}
            >
              {formatFn(value)}
            </span>

            {isNaN(change) ? (
              <span>&nbsp;</span>
            ) : (
              <div
                className={classNames('flex items-center', {
                  'text-lima-500': (!isNegative && !inverseColor) || (isNegative && inverseColor),
                  'text-radical-red-700': (isNegative && !inverseColor) || (!isNegative && inverseColor),
                  'ml-3': !below,
                })}
              >
                <UpIcon
                  className={classNames('h-3 w-3 fill-current', {
                    'scale-y-[-1]': isNegative,
                  })}
                />

                <span
                  className={classNames('ml-1 text-xs', {
                    'text-lima-500': (!isNegative && !inverseColor) || (isNegative && inverseColor),
                    'text-radical-red-700': (isNegative && !inverseColor) || (!isNegative && inverseColor),
                  })}
                >
                  {isNegative ? change : `+${change}`}%
                </span>
              </div>
            )}
          </>
        )}
      </div>
      {extraText ? extraText : null}
    </div>
  );
}
