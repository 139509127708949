export const SURVEY_QUESTION_TYPES = {
  MULTI_CHOICE: 'multi-choice',
  TEXT_ENTRY: 'text-entry',
  STATIC_DISPLAY: 'static-display',
  RATING: 'rating',
};

export const TEXT_ENTRY_SUBTYPES = {
  LONG: 'long',
  SHORT: 'short',
  EMAIL: 'email',
};

export const RATING_SUBTYPES = {
  NPS: 'NPS',
  STARS: 'stars',
  SMILEY: 'smiley',
  NUMERICAL: 'numerical',
};

export const MULTI_CHOICE_SUBTYPES = {
  SINGLE: 'single',
  MULTI: 'multi',
};

export const RANDOMIZE_OPTIONS = {
  NONE: 'none',
  ALL: 'all',
  ALL_BUT_FIRST: 'all-but-first',
  ALL_BUT_LAST: 'all-but-last',
};

export const STATIC_DISPLAY_SUBTYPES = {
  NORMAL: 'normal',
  THANK_YOU: 'thank-you',
};

export const LOGIC_COMPARISONS = {
  IS_SUBMITTED: 'is-submitted',
  IS_SKIPPED: 'is-skipped',
  IS_EXACTLY: 'is-exactly',
  CONTAINS: 'contains',
  IS_ANY_OF: 'is-any-of',
};

export const LOGIC_ACTIONS = {
  SHOW_QUESTION: 'show-question',
  CLOSE: 'close',
  OPEN_URL: 'open-url',
  MAILTO: 'email',
  PHONE: 'phone',
};

export const TARGET_OPTIONS = {
  SAME_WINDOW: 'same-window',
  NEW_TAB: 'new-tab',
  NEW_WINDOW: 'new-window',
};

export const SHOW_QUESTION_OPTIONS = {
  FIRST: 'first',
  NEXT: 'next',
  LAST: 'last',
  THANK_YOU: 'thank-you',
};

export const CTA_TYPES = {
  BAR: 'bar',
  BUTTON: 'button',
  POPUP: 'popup',
};

export const URL_TYPES = {
  URL: 'url-link',
  MAILTO: 'mailto-link',
  PHONE: 'tel-link',
};

export const PREVIEW_READY = 'PREVIEW_READY';

export const ADDONS_CAPABILITIES = {
  CLOSABLE: 'CLOSABLE',
  LABEL_MULTIPLE_CHOICE: 'LABEL_MULTIPLE_CHOICE',
  MULTIPLE_URLS_TYPE: 'MULTIPLE_URLS_TYPE',
  CLOSABLE_OPTIONS: 'CLOSABLE_OPTIONS',
  SAMPLING: 'SAMPLING',
  POPUP_IMAGE: 'POPUP_IMAGE',
  CUSTOM_BRANDING: 'CUSTOM_BRANDING',
  COLOR_ACCESSIBILITY: 'COLOR_ACCESSIBILITY',
  BUTTON_CTA_TOOLTIP_MARKDOWN: 'BUTTON_CTA_TOOLTIP_MARKDOWN',
  MESSAGE_QUESTION_TYPE: 'MESSAGE_QUESTION_TYPE',
};

export const CLOSE_OPTIONS = {
  EVERY_TIME: 'every_time',
  SESSION: 'session',
  DAYS_1: '1_days',
  DAYS_3: '3_days',
  DAYS_7: '7_days',
  FOREVER: 'forever',
};

export const BOTTOM = 'bottom';
export const TOP = 'top';
export const LEFT = 'left';
export const RIGHT = 'right';
export const CENTER = 'center';

export const EXECUTE_JS = 'execute-js';

export const SENTIMENT_VALUES = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
  MIXED: 'MIXED',
  UNKNOWN: 'UNKNOWN',
};

export const BEHAVIOR_TRIGGERS = {
  IMMEDIATE: 'immediate',
  TIME_DELAY: 'timeDelay',
  SCROLL_PAST_FOLD: 'scrollPastFold',
  EXIT_INTENT: 'exitIntent',
  NUM_PAGES: 'numPages',
  FEEDBACK: 'feedback',
};

export const REPEAT_VALUES = {
  NUMBER: 'number',
  SUBMIT: 'submit',
  ALWAYS: 'always',
};

export const REFERRER_VALUES = {
  EVERYONE: 'everyone',
  DIRECT_TRAFFIC: 'direct-traffic',
  REFERRER: 'referrer',
};

export const SURVEY_DISPLAY_TYPES = {
  POPUP: 'popup',
  EXTERNAL: 'external',
};

/* div id where survey/cta preview is rendered*/
export const ADDON_PREVIEW_ID = 'survey-preview';

/* list of markdown type supported */
export const MARKDOWN_TYPES = {
  INLINE: 'inline',
  BLOCK: 'block',
  INLINE_NO_IMG: 'inline_no_img',
};

export const ALL_RESPONSES_TABLE_ORDER = {
  CREATED_AT: 'CREATED_AT',
  QUESTION_ID: 'QUESTION_ID',
  SURVEY_URL: 'SURVEY_URL',
};

export const AUDIENCE_MODES = {
  ADVANCED: 'ADVANCED',
  SIMPLE: 'SIMPLE',
};

export const ADDON_TYPES = {
  CTA: 'CTA',
  SURVEY: 'SURVEY',
};

export const ADDON_STATUSES = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};
