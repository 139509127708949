import jwtDecode from 'jwt-decode';

import { checkSharingSession, hasSharingToken } from '/src/utils';
import { getParam } from '/src/utils/location';
import { selectivelyClearLocalStorage } from '/src/utils/storage';
import { AUTH_TOKEN_KEY, AUTH_TOKEN_EXPIRATION_OFFSET_MS } from '/src/features/_global/constants';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import { AppConfig } from '../AppConfig';

const ACCOUNT_SWITCH = 'ACCOUNT_SWITCH';

export function removeToken() {
  window.sessionStorage.clear();
  selectivelyClearLocalStorage();
}

export async function updateClockSkewFromApi() {
  const serverTime = await getServerTime();
  const localTime = new Date();
  const seconds = Math.floor(differenceInSeconds(serverTime, localTime));
  window.sessionStorage.setItem('CLOCK_DRIFT', seconds);
}

export async function updateClockSkewFromToken(token) {
  const payload = jwtDecode(token);
  const serverTime = payload.iat;
  const localTime = new Date();
  const seconds = Math.floor(differenceInSeconds(localTime, fromUnixTime(serverTime)));
  window.sessionStorage.setItem('CLOCK_DRIFT', seconds);
}

export function hydrateToken() {
  const token = getParam('token') || window.localStorage.getItem(AUTH_TOKEN_KEY);
  if (token && getParam('token') && !hasSharingToken(token)) {
    window.localStorage.setItem(AUTH_TOKEN_KEY, getParam('token'));
  }
  if (!token || token === 'undefined' || token === 'null') {
    removeToken();
    return;
  }
  return token;
}

export function storeToken(token = null) {
  if (token && token !== 'undefined' && token !== 'null') {
    window.localStorage.setItem(AUTH_TOKEN_KEY, token);
  }
}

export function validToken(token) {
  const resolvedToken = token ? token.replace('share_', '') : hydrateToken();
  const clockDrift = parseInt(window.sessionStorage.getItem('CLOCK_DRIFT') || 0);

  if (resolvedToken) {
    try {
      const payload = jwtDecode(resolvedToken);

      if (payload.exp && Date.now() < (payload.exp + clockDrift) * 1000) {
        return true;
      }
    } catch {
      // invalid token
      removeToken();
      return false;
    }
  }
  return false;
}

export function timeUntilTokenRefetch(token, currentTime = new Date().getTime()) {
  const payload = jwtDecode(token);
  return payload.exp * 1000 - AUTH_TOKEN_EXPIRATION_OFFSET_MS - currentTime;
}

export function decodedToken(token) {
  const resolvedToken = token ? token : hydrateToken();
  try {
    return jwtDecode(resolvedToken);
  } catch {
    return null;
  }
}

export function goToLogin(includeRedirectBack) {
  const currentPath = window.location.href.replace(window.location.origin, '');

  if (includeRedirectBack) {
    window.location.href = `${AppConfig.authBaseURL()}/login?redirect=${encodeURIComponent(currentPath)}`;
  } else {
    window.location.href = `${AppConfig.authBaseURL()}/login`;
  }
}

export function goToCurrentDashboard() {
  // remove any last segment with ids as it will likely not be allowed after the account switch
  const dashboardPath = window.location.pathname.split('/').slice(0, 2).join('/');
  window.location.href = `${window.SHELL_APP_URL}${dashboardPath}`;
}

function ejectFromV2() {
  removeToken();
  goToLogin();
}

const fetchToken = async ({ signal }) => {
  try {
    const response = await fetch(`${AppConfig.authBaseURL()}/authenticate`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      signal,
    });

    const json = await response.json();
    return json.access_token;
  } catch (err) {
    console.error(err);
  }
};

export async function fetchNewToken() {
  const controller = new AbortController();
  const signal = controller.signal;
  try {
    if (checkSharingSession() && window.shr && hasSharingToken(getParam('token'))) {
      return { token: window.shr, isSharing: true };
    }
    if (window.Cypress) return { token: window.localStorage.getItem(AUTH_TOKEN_KEY), isSharing: checkSharingSession() };
    const newToken = await fetchToken({ signal });
    if (newToken) {
      storeToken(newToken);
    }
    return newToken ? { token: newToken, isSharing: checkSharingSession() } : false;
  } catch {
    return false;
  }
}

const fetchServerTime = async ({ signal }) => {
  try {
    const response = await fetch(window.CLOCK_API_URL, {
      signal,
    });
    if (response.status >= 400) {
      ejectFromV2();
    }
    const parsedResponse = await response.text();
    const [serverTime] = parsedResponse.split(',');
    return parseInt(serverTime);
  } catch (err) {
    console.error(err);
  }
};

async function getServerTime() {
  const controller = new AbortController();
  const signal = controller.signal;
  return await fetchServerTime({ signal });
}

export function hasLoginToken(token) {
  const payload = decodedToken(token ? token : window.localStorage.getItem(AUTH_TOKEN_KEY));
  return !!payload?.sub;
}

export function parseShareToken(token) {
  const shareJwt = token.replace('share_', '');
  const payload = decodedToken(shareJwt);
  return payload.shared_item;
}

export function dispatchAccountChange(message, data = undefined) {
  window.accountChannel.postMessage({
    message,
    data,
  });
}

function handleAccountChange({ message, data }) {
  if (message === ACCOUNT_SWITCH) {
    const tokenPayload = decodedToken();
    if (tokenPayload?.sub !== data?.userId) {
      // reload the page
      window.location.reload();
    }
  }
}

export function setupAccountListeners() {
  if (!window.accountChannel) return;

  window.accountChannel.addEventListener('message', handleAccountChange);
}

export function teardownAccountListeners() {
  window.accountChannel.removeEventListener('message', handleAccountChange);
}
