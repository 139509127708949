import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const abTestsFeatureKeys = createQueryKeys('abTestsFeature', {
  abTestList: ({ site, search, status, order, sort, limit }) => [{ site, search, status, order, sort, limit }],
  abTestDetail: ({ id, startDate, endDate, presetDate }) => [{ id, startDate, endDate, presetDate }],
  abTestDetailV2: ({ id, startDate, endDate, presetDate, includeGoals, goalId, order, sort }) => [
    { id, startDate, endDate, presetDate, includeGoals, goalId, order, sort },
  ],
  abTestDistribution: ({ id, goalId, presetDate, startDate, endDate }) => [
    { id, goalId, presetDate, startDate, endDate },
  ],
});

export const abTestListQuery = ({ site, search, status, order, sort, limit }) => ({
  ...abTestsFeatureKeys.abTestList({ site, search, status, order: { field: order, sort }, limit }),
  useErrorBoundary: false,
  getNextPageParam: (lastPage) => (lastPage.abTestList.hasNextPage ? lastPage.abTestList.nextPageCursor : undefined),
  meta: {
    query: gql`
      query AbTestList(
        $cursor: String
        $limit: Int
        $search: String
        $site: Int
        $status: AbTestStatus
        $order: AbTestListOrder
      ) {
        abTestList(cursor: $cursor, limit: $limit, search: $search, site: $site, status: $status, order: $order) {
          list {
            autoReweight
            bestVariant {
              id
              variantName
              viewVariantUrl
              position
              type
              redirectUrl
              weight
              results {
                conversionRate
                improvementFromControl
              }
              significance
            }
            createdAt
            createdByUserId
            createdByUserName
            devices
            duration
            goal {
              id
              name
              displayData
              icon
              historical
            }
            hasErrors
            hasResults
            id
            matchingUrl
            name
            pageUrl
            publishedAt
            recentlyCreated
            scriptInstalled
            siteId
            status
            stoppedAt
            thumbnailUrl
            screenshotUrl
            totalConversions
            totalVisitors
            type
            variantsCount
            significance
            permissions {
              canAdjustWeight
              canViewResults
              canStop
              canShare
              canChangeGoal
              canRename
              canCheckInstallation
              canDelete
              canAddVariants
              canDuplicate
            }
            variants {
              id
              variantName
              viewVariantUrl
              position
              type
              redirectUrl
              weight
              significance
              results {
                conversions
                visitors
                conversionRate
                improvementFromControl
              }
            }
          }
          nextPageCursor
          hasNextPage
        }
      }
    `,
  },
});

export const abTestDetailQuery = ({ id, presetDate, startDate, endDate }) => ({
  ...abTestsFeatureKeys.abTestDetail({ id, presetDate, startDate, endDate }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query AbTestDetail($id: Int!, $startDate: Int, $endDate: Int, $presetDate: String) {
        abTestDetail(id: $id, startDate: $startDate, endDate: $endDate, presetDate: $presetDate) {
          autoReweight
          bestVariantId
          createdAt
          createdByUserId
          createdByUserName
          devices
          draft
          duration
          goal {
            id
            name
            displayData
            icon
            historical
          }
          selectedGoal {
            id
            name
            hasValue
          }
          conversionGoals {
            id
            name
          }
          hasErrors
          hasResults
          id
          matchingUrl
          name
          notes
          pageUrl
          permissions {
            canAddVariants
            canDuplicate
            canAdjustWeight
            canChangeGoal
            canRename
            canCheckInstallation
            canDelete
            canShare
            canStop
            canViewResults
          }
          publishedAt
          publishedBy {
            name
          }
          recentlyCreated
          scriptInstalled
          siteId
          status
          stoppedAt
          thumbnailUrl
          totalConversions
          totalVisitors
          totalValue
          avgConversionRate
          type
          variants {
            createdAt
            updatedAt
            editorPatch
            redirectUrl
            hasErrors
            id
            isRetired
            permissions {
              canDuplicate
              canEdit
              canRetire
              canViewRecordings
              canViewSnapshot
            }
            position
            results {
              conversionRate
              conversions
              value
              hasBestImprovement
              improvementFromControl
              trafficSplit
              visitors
            }
            resultsId
            retiredAt
            snapshotId
            thumbnailUrl
            screenshotUrl
            type
            variantName
            viewVariantUrl
            weight
            significance
            description
          }
          variantsCount
          significance
          originalId
        }
      }
    `,
  },
});

export const abTestDetailV2Query = ({ id, presetDate, startDate, endDate, includeGoals, goalId, order, sort }) => ({
  ...abTestsFeatureKeys.abTestDetailV2({
    id,
    presetDate,
    startDate,
    endDate,
    includeGoals,
    goalId,
    order: { field: order, sort },
  }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query AbTestDetailV2(
        $id: Int!
        $goalId: Int
        $startDate: Int
        $endDate: Int
        $presetDate: String
        $includeGoals: Boolean
        $order: AbTestVariantOrder
      ) {
        abTestDetailV2(
          id: $id
          goalId: $goalId
          startDate: $startDate
          endDate: $endDate
          presetDate: $presetDate
          includeGoals: $includeGoals
          order: $order
        ) {
          autoReweight
          bestVariantId
          createdAt
          createdByUserId
          createdByUserName
          devices
          draft
          duration
          goal {
            id
            name
            displayData
            icon
            historical
            hasValue
          }
          selectedGoal {
            id
            name
            hasValue
          }
          conversionGoals {
            id
            name
          }
          hasErrors
          hasResults
          id
          matchingUrl
          name
          notes
          pageUrl
          permissions {
            canAddVariants
            canDuplicate
            canAdjustWeight
            canChangeGoal
            canRename
            canCheckInstallation
            canDelete
            canShare
            canStop
            canViewResults
          }
          publishedAt
          publishedBy {
            name
          }
          recentlyCreated
          scriptInstalled
          siteId
          status
          stoppedAt
          thumbnailUrl
          totalConversions
          totalVisitors
          totalValue
          avgConversionRate
          type
          variants {
            createdAt
            updatedAt
            editorPatch
            redirectUrl
            hasErrors
            id
            isRetired
            permissions {
              canDuplicate
              canEdit
              canRetire
              canViewRecordings
              canViewSnapshot
            }
            position
            results {
              conversionRate
              conversions
              value
              hasBestImprovement
              improvementFromControl
              trafficSplit
              visitors
            }
            resultsId
            retiredAt
            snapshotId
            thumbnailUrl
            screenshotUrl
            type
            variantName
            viewVariantUrl
            weight
            significance
            description
          }
          variantsCount
          significance
          originalId
        }
      }
    `,
  },
});

export const abTestDistributionQuery = ({ id, goalId, presetDate, startDate, endDate }) => ({
  ...abTestsFeatureKeys.abTestDistribution({ id, goalId, presetDate, startDate, endDate }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query AbTestDistribution($id: Int!, $goalId: Int!, $startDate: Int, $endDate: Int, $presetDate: String) {
        abTestDistribution(
          id: $id
          goalId: $goalId
          startDate: $startDate
          endDate: $endDate
          presetDate: $presetDate
        ) {
          date
          startDate
          endDate
          granularity
          distribution {
            variantId
            totalConversions
            totalVisitors
            totalValue
            avgConversionRate
          }
        }
      }
    `,
  },
});
