import { useRef } from 'react';
import classNames from 'classnames';
import { useFormikContext, FieldArray } from 'formik';
import { useDrag, useDrop } from 'react-dnd';
import { Popover, Indicator, Tooltip, Checkbox } from '@crazyegginc/hatch';

import { Toggle, Input, SectionHeader, SectionPanel, SectionContent } from '../../../common/basic-ui';
import { QuestionSelector } from '../dropdowns/QuestionSelector';
import { QuestionMenu } from '../dropdowns/QuestionMenu';
import { QuestionTitle } from './QuestionTitle';
import { MultiChoices } from './MultiChoices';
import { ActionSelector } from './SurveyLogic';
import { RatingValuesLabels } from './RatingValuesLabels';
import { CustomizeOptions } from './CustomizeOptions';
import { AddQuestionButton } from './AddQuestionButton';
import { ThankYouMessage } from './ThankYouMessage';
import {
  SURVEY_QUESTION_TYPES,
  TEXT_ENTRY_SUBTYPES,
  MULTI_CHOICE_SUBTYPES,
  RATING_SUBTYPES,
  STATIC_DISPLAY_SUBTYPES,
} from '/src/features/addons/constants';
import { generateInitialQuestionSchema, DEFAULT_EMAIL_PLACEHOLDER, DEFAULT_NEXT_TITLE } from '../SurveyEditor';
import { useEditorContext } from '../../../editor-context';
import { getDefaultLogicStatement } from '../editor-functions';
import { mergeDeep } from '/src/utils/object';

import { ReactComponent as MoveIcon } from '@crazyegginc/hatch/dist/images/icon-move-dots.svg';
import { ReactComponent as MultiChoiceIcon } from '@crazyegginc/hatch/dist/images/icon-multi-choice.svg';
import { ReactComponent as LogicIcon } from '@crazyegginc/hatch/dist/images/icon-logic.svg';
import { ReactComponent as TextIcon } from '@crazyegginc/hatch/dist/images/icon-text.svg';
import { ReactComponent as StarIcon } from '@crazyegginc/hatch/dist/images/icon-star-outline.svg';
import { ReactComponent as NumericIcon } from '@crazyegginc/hatch/dist/images/icon-one-to-five.svg';
import { ReactComponent as NPSIcon } from '@crazyegginc/hatch/dist/images/icon-nps.svg';
import { ReactComponent as EmojiIcon } from '@crazyegginc/hatch/dist/images/icon-visitorface-4.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function Questions() {
  const { readonly } = useEditorContext();
  const { values, errors } = useFormikContext();

  return (
    <>
      <FieldArray name="questions">
        {(questionActions) => (
          <div>
            {values.questions?.map((question, i) => {
              // Exclude thank you message
              if (values.questions[i].subtype === STATIC_DISPLAY_SUBTYPES.THANK_YOU) {
                return null;
              }

              return <Question key={question.id} questionActions={questionActions} index={i} />;
            })}

            {!readonly && <AddQuestionButton questionActions={questionActions} />}
            {typeof errors.questions === 'string' ? (
              <div className="text-error ml-10 mt-1">{errors.questions}</div>
            ) : null}

            <ThankYouMessage questionActions={questionActions} />
          </div>
        )}
      </FieldArray>
    </>
  );
}

function Question({ questionActions, index }) {
  const { readonly, publishedEdit, publishedQuestionIds } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();
  const ref = useRef(null);
  const dragRef = useRef(null);

  const q = values.questions[index];

  const questionTypeChangeDisabled = readonly || (publishedEdit && publishedQuestionIds.includes(q.id));

  const [, drop] = useDrop({
    accept: 'question',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    canDrop: () => !readonly,
    hover(item, monitor) {
      if (!ref.current || !monitor.canDrop()) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      questionActions.move(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'question',
    item: () => {
      return { id: q.id, index };
    },
    canDrag: () => !readonly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));
  drag(dragRef);

  function changeQuestionType(newType, newSubtype) {
    const defaultOldType = generateInitialQuestionSchema(q.type, q.subtype);
    const defaultNewType = generateInitialQuestionSchema(newType, newSubtype);

    let merged = Object.assign(
      mergeDeep(generateInitialQuestionSchema(newType, newSubtype), JSON.parse(JSON.stringify(q))),
      {
        type: newType,
        subtype: newSubtype,
      },
    );

    // Don't copy the old values if they are the defaults
    if (q.requiredErrorMessage === defaultOldType.requiredErrorMessage) {
      merged.requiredErrorMessage = defaultNewType.requiredErrorMessage;
    }
    if (q.title === defaultOldType.title) {
      merged.title = defaultNewType.title;
    }
    if (merged.min && defaultNewType.min && q.min?.label === defaultOldType.min?.label) {
      merged.min.label = defaultNewType.min.label;
    }
    if (merged.max && defaultNewType.max && q.max?.label === defaultOldType.max?.label) {
      merged.max.label = defaultNewType.max.label;
    }

    questionActions.replace(index, window.CE2.Survey.castToSchema(merged));
  }

  return (
    <div ref={ref} className={classNames('relative', { 'opacity-0': isDragging })}>
      <div className="absolute left-2 top-2.5 cursor-[grab]" ref={dragRef}>
        <div className="text-body-4 flex h-6 w-6 items-center justify-center rounded-full border border-mystic-500 font-mono font-semibold leading-none">
          {index + 1}
        </div>
        {!readonly && <MoveIcon className="ml-1 mt-1.25 h-4 w-4 rotate-90 fill-current text-cadet-blue-500" />}
      </div>
      <QuestionPanel index={index}>
        <SectionHeader>
          <Popover className="relative">
            <>
              <Popover.Button
                className="group flex items-center"
                disabled={questionTypeChangeDisabled}
                aria-label="change question type"
              >
                <QuestionName index={index} />
                <Indicator
                  type="dropdown"
                  className={classNames('ml-2.5 text-cadet-blue-500', {
                    'group-hover:text-lynch-500': !questionTypeChangeDisabled,
                  })}
                />
              </Popover.Button>
              <QuestionSelector onSelection={changeQuestionType} />
            </>
          </Popover>

          <div className="ml-5 flex items-center space-x-6">
            {q.logic?.statements?.length > 0 && (
              <Tooltip tooltipContent="Custom logic is enabled for this question.">
                <LogicIcon className="h-4 w-4 fill-current text-dodger-blue-500" />
              </Tooltip>
            )}
            {q.type === SURVEY_QUESTION_TYPES.STATIC_DISPLAY ? null : (
              <Toggle
                label="Required"
                setEnabled={() => setFieldValue(`questions[${index}].optional`, !q.optional)}
                enabled={!q.optional}
                disabled={readonly}
              />
            )}
            {!readonly && <QuestionMenu index={index} questionActions={questionActions} />}
          </div>
        </SectionHeader>

        <SectionContent>
          {q.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE && <MultiChoice index={index} />}
          {q.type === SURVEY_QUESTION_TYPES.TEXT_ENTRY && <TextEntry index={index} />}
          {q.type === SURVEY_QUESTION_TYPES.RATING && <Rating index={index} />}
          {q.type === SURVEY_QUESTION_TYPES.STATIC_DISPLAY && <StaticDisplay index={index} />}
        </SectionContent>
      </QuestionPanel>
    </div>
  );
}

export function QuestionPanel({ index, children }) {
  return (
    <SectionPanel
      data-testid="question"
      tabIndex={-1}
      onFocus={() => {
        if (typeof index === 'number') {
          window.CE2.removeFeedbackButton?.();
          window.CE2.Survey?.update(1, { questionNumber: index });
        }
      }}
    >
      {children}
    </SectionPanel>
  );
}

function MultiChoice({ index }) {
  return (
    <>
      <QuestionTitle index={index} />
      <MultiChoices index={index} />
      <CustomizeOptions index={index} />
    </>
  );
}

function TextEntry({ index }) {
  const { readonly } = useEditorContext();
  const { values, touched, errors, handleBlur, handleChange } = useFormikContext();
  return (
    <>
      <QuestionTitle index={index} />
      <Input
        label={
          <>
            <span className="text-body-1">Placeholder text</span>
            <span className="text-body-5 ml-2.5">Optional</span>
          </>
        }
        id={`questions[${index}].placeholder`}
        name={`questions[${index}].placeholder`}
        value={values.questions[index].placeholder}
        error={
          errors.questions?.[index]?.placeholder && touched.questions?.[index]?.placeholder
            ? errors.questions[index].placeholder
            : null
        }
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={(e) => {
          if (e.target.value === DEFAULT_EMAIL_PLACEHOLDER) {
            e.target.select();
          }
        }}
        disabled={readonly}
      />
      <CustomizeOptions index={index} />
    </>
  );
}

function Rating({ index }) {
  return (
    <>
      <QuestionTitle index={index} />
      <RatingValuesLabels index={index} />
      <CustomizeOptions index={index} />
    </>
  );
}

function StaticDisplay({ index }) {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = useFormikContext();

  return (
    <>
      <QuestionTitle index={index} titleLabel="Heading" />
      <Checkbox
        label="Add a button"
        id={`questions[${index}].nextButton.enabled`}
        name={`questions[${index}].nextButton.enabled`}
        checked={values.questions[index].nextButton?.enabled ?? false}
        onChange={async (e) => {
          if (e.target.checked) {
            await setFieldValue(`questions[${index}].logic.statements`, [
              getDefaultLogicStatement(values.questions, index),
            ]);
            setFieldValue(`questions[${index}].nextButton.enabled`, true);
          } else {
            await setFieldValue(`questions[${index}].nextButton.enabled`, false);
            setFieldValue(`questions[${index}].logic.statements`, []);
          }
        }}
        disabled={readonly}
      />
      {values.questions[index].nextButton?.enabled ? (
        <div className="flex items-start">
          <div className="mr-2.5 w-1/2 flex-grow">
            <Input
              label="Button text"
              id={`questions[${index}].nextButton.title`}
              name={`questions[${index}].nextButton.title`}
              value={values.questions[index].nextButton.title}
              error={
                errors.questions?.[index]?.nextButton?.title && touched.questions?.[index]?.nextButton?.title
                  ? errors.questions[index].nextButton.title
                  : null
              }
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={(e) => {
                if (e.target.value === DEFAULT_NEXT_TITLE) {
                  e.target.select();
                }
              }}
              disabled={readonly}
            />
          </div>
          <div className="w-1/2 flex-grow">
            <div className="font-semibold text-sm mb-1 pl-3">When clicked:</div>
            <ActionSelector questionIndex={index} statementIndex={0} readonly={readonly} />
          </div>
        </div>
      ) : null}
    </>
  );
}

function QuestionName({ index }) {
  const { values } = useFormikContext();

  const { type, subtype } = values.questions[index];

  let text;
  let Icon;
  switch (type) {
    case SURVEY_QUESTION_TYPES.STATIC_DISPLAY:
      switch (subtype) {
        case STATIC_DISPLAY_SUBTYPES.NORMAL:
          text = 'Message';
          Icon = InfoIcon;
          break;
      }
      break;
    case SURVEY_QUESTION_TYPES.MULTI_CHOICE:
      Icon = MultiChoiceIcon;
      switch (subtype) {
        case MULTI_CHOICE_SUBTYPES.SINGLE:
          text = 'Multiple Choice Single-Select';
          break;
        case MULTI_CHOICE_SUBTYPES.MULTI:
          text = 'Multiple Choice Multi-Select';
          break;
      }
      break;
    case SURVEY_QUESTION_TYPES.TEXT_ENTRY:
      Icon = TextIcon;
      switch (subtype) {
        case TEXT_ENTRY_SUBTYPES.LONG:
          text = 'Long Text Answer';
          break;
        case TEXT_ENTRY_SUBTYPES.SHORT:
          text = 'Short Text Answer';
          break;
        case TEXT_ENTRY_SUBTYPES.EMAIL:
          text = 'Email';
          break;
      }
      break;
    case SURVEY_QUESTION_TYPES.RATING:
      switch (subtype) {
        case RATING_SUBTYPES.NPS:
          Icon = NPSIcon;
          text = 'NPS Rating';
          break;
        case RATING_SUBTYPES.STARS:
          Icon = StarIcon;
          text = 'Star Rating';
          break;
        case RATING_SUBTYPES.NUMERICAL:
          Icon = NumericIcon;
          text = 'Numeric Rating';
          break;
        case RATING_SUBTYPES.SMILEY:
          Icon = EmojiIcon;
          text = 'Emoji';
          break;
      }
      break;
  }

  return (
    <div className="flex items-center">
      <Icon className="mr-1.25 h-[18px] w-[18px] fill-current text-cadet-blue-500" />
      {text}
    </div>
  );
}
