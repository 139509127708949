import Honeybadger from '@honeybadger-io/js';
import { produce } from 'immer';
import { v4 as uuid } from 'uuid';

import { LOGICALS } from '/src/features/_global/constants';
import {
  ADDON_PREVIEW_ID,
  SURVEY_DISPLAY_TYPES,
  SURVEY_QUESTION_TYPES,
  RATING_SUBTYPES,
  STATIC_DISPLAY_SUBTYPES,
  LOGIC_COMPARISONS,
  LOGIC_ACTIONS,
  MULTI_CHOICE_SUBTYPES,
  SHOW_QUESTION_OPTIONS,
  TARGET_OPTIONS,
  ADDON_TYPES,
} from '/src/features/addons/constants';

import { DEFAULT_DROPDOWN_SELECT_TEXT, DEFAULT_DROPDOWN_MULTIPLE_ITEMS_TEXT } from './SurveyEditor';

import { inflect } from '/src/utils/string';
import { replaceEmojiWithImage, replaceEmoji } from '../../common/common-functions';
import {
  generateAudienceAddonFormat,
  generateBehaviorAddonFormat,
  generateAudienceEditorFormat,
  generateBehaviorEditorFormat,
} from '../../common/editor/common-editor-functions';

import { ReactComponent as StarIcon } from '@crazyegginc/hatch/dist/images/icon-star-filled.svg';

import { ReactComponent as AnguishedEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-anguished.svg';
import { ReactComponent as SadEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-sad.svg';
import { ReactComponent as NeutralEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-neutral.svg';
import { ReactComponent as HappyEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-happy.svg';
import { ReactComponent as GrinningEmojiIcon } from '@crazyegginc/hatch/dist/images/illustration-emoji-grinning.svg';

// editor data format -> survey data format
export function generateSurveyDataFormat({ values, isTemplate, id, publishedEdit, publishDraft }) {
  const isExternal = values.displayType === SURVEY_DISPLAY_TYPES.EXTERNAL;

  const content = JSON.stringify({
    questions: generateQuestionSurveyFormat(values.questions),
    theme: values.theme,
    closable: values.closable,
    position: values.position,
    autoPosition: values.autoPosition,
    autoPositionFallbackToShow: values.autoPositionFallbackToShow,
    mode: values.mode,
    external: isExternal,
    sampling: values.sampling,
    audience: isExternal ? {} : generateAudienceAddonFormat(values.audience),
    behavior: isExternal ? {} : generateBehaviorAddonFormat(values.behavior),
  });

  if (isTemplate) {
    if (id) {
      // AddonTemplateEditParams
      return {
        category: values.category,
        content,
        description: values.description,
        id: parseInt(id),
        name: values.name,
      };
    } else {
      // AddonTemplateCreateParams
      return {
        category: values.category,
        content,
        description: values.description,
        name: values.name,
        type: ADDON_TYPES.SURVEY,
      };
    }
  } else {
    if (id) {
      // AddonEditParams
      if (publishedEdit) {
        if (publishDraft) {
          return {
            id,
            name: values.name,
            content,
            draftName: null,
            draftContent: null,
            siteId: values.audience.site.id,
          };
        }
        return {
          id,
          draftName: values.name,
          draftContent: content,
          siteId: values.audience.site.id,
        };
      }
      return {
        id,
        content,
        name: values.name,
        siteId: values.audience.site.id,
      };
    } else {
      // AddonCreateParams
      return {
        active: false,
        content,
        name: values.name,
        type: ADDON_TYPES.SURVEY,
        addonTemplateId: values.addonTemplateId !== undefined ? parseInt(values.addonTemplateId) : null,
        suggestionId: values.suggestionId !== undefined ? parseInt(values.suggestionId) : null,
      };
    }
  }
}

export function generateQuestionSurveyFormat(questions) {
  return questions.map((question) => ({
    ...JSON.parse(replaceEmoji(JSON.stringify({ ...question }))),
    logic: {
      ...question.logic,
      statements: question.logic.statements.map((statement) => {
        return produce(statement, (draft) => {
          switch (statement.actions[0].editorAction) {
            case undefined:
              break;
            case SHOW_QUESTION_OPTIONS.NEXT:
            case SHOW_QUESTION_OPTIONS.LAST:
            case SHOW_QUESTION_OPTIONS.FIRST:
            case SHOW_QUESTION_OPTIONS.THANK_YOU:
              draft.actions[0].action = LOGIC_ACTIONS.SHOW_QUESTION;
              draft.actions[0].questionId = statement.actions[0].editorAction;
              break;
            case LOGIC_ACTIONS.CLOSE:
              draft.actions[0].action = LOGIC_ACTIONS.CLOSE;
              break;
            case 'open-same':
              draft.actions[0].action = LOGIC_ACTIONS.OPEN_URL;
              draft.actions[0].target = TARGET_OPTIONS.SAME_WINDOW;
              break;
            case 'open-tab':
              draft.actions[0].action = LOGIC_ACTIONS.OPEN_URL;
              draft.actions[0].target = TARGET_OPTIONS.NEW_TAB;
              break;
            case 'open-window':
              draft.actions[0].action = LOGIC_ACTIONS.OPEN_URL;
              draft.actions[0].target = TARGET_OPTIONS.NEW_WINDOW;
              break;
            case 'open-mail':
              draft.actions[0].action = LOGIC_ACTIONS.MAILTO;
              break;
            case 'open-tel':
              draft.actions[0].action = LOGIC_ACTIONS.PHONE;
              break;
            case statement.actions[0].editorAction?.match(/^id-.+/)?.input:
              draft.actions[0].action = LOGIC_ACTIONS.SHOW_QUESTION;
              draft.actions[0].questionId = statement.actions[0].editorAction.replace(/^id-/, '');
              break;
          }
          delete draft.actions[0].editorAction;
        });
      }),
    },
  }));
}

function generateQuestionEditorFormat(questions) {
  return questions.map((question) => ({
    ...question,
    logic: {
      ...question.logic,
      version: question.logic?.version ?? 1,
      statements:
        question.logic?.statements.map((statement) => {
          return produce(statement, (draft) => {
            switch (statement.actions[0].action) {
              case LOGIC_ACTIONS.SHOW_QUESTION:
                {
                  switch (statement.actions[0].questionId) {
                    case SHOW_QUESTION_OPTIONS.NEXT:
                    case SHOW_QUESTION_OPTIONS.LAST:
                    case SHOW_QUESTION_OPTIONS.FIRST:
                    case SHOW_QUESTION_OPTIONS.THANK_YOU:
                      draft.actions[0].editorAction = statement.actions[0].questionId;
                      break;
                    default:
                      draft.actions[0].editorAction = `id-${statement.actions[0].questionId}`;
                  }
                  delete draft.actions[0].questionId;
                }
                break;
              case LOGIC_ACTIONS.CLOSE:
                draft.actions[0].editorAction = LOGIC_ACTIONS.CLOSE;
                break;
              case LOGIC_ACTIONS.MAILTO: {
                draft.actions[0].editorAction = 'open-mail';
                break;
              }
              case LOGIC_ACTIONS.PHONE: {
                draft.actions[0].editorAction = 'open-tel';
                break;
              }
              case LOGIC_ACTIONS.OPEN_URL: {
                switch (statement.actions[0].target) {
                  case TARGET_OPTIONS.SAME_WINDOW:
                    draft.actions[0].editorAction = 'open-same';
                    break;
                  case TARGET_OPTIONS.NEW_TAB:
                    draft.actions[0].editorAction = 'open-tab';
                    break;
                  case TARGET_OPTIONS.NEW_WINDOW:
                    draft.actions[0].editorAction = 'open-window';
                    break;
                }
                delete draft.actions[0].target;
              }
            }
            delete draft.actions[0].action;
          });
        }) ?? [],
    },
    dropdownSelect: {
      defaultText: question.dropdownSelect?.defaultText ?? DEFAULT_DROPDOWN_SELECT_TEXT,
      multipleItemsText: question.dropdownSelect?.multipleItemsText ?? DEFAULT_DROPDOWN_MULTIPLE_ITEMS_TEXT,
    },
  }));
}

// survey data format -> editor data format
export function generateSurveyEditorFormat({
  name,
  content,
  description,
  category,
  addonTemplateId,
  suggestionId,
  site,
}) {
  let parsedContent = {};

  try {
    parsedContent = JSON.parse(content);
  } catch (error) {
    Honeybadger.notify('Failed to parse saved survey content', {
      context: { error, content },
      name: 'Edit survey error',
    });
  }

  const result = {
    ...parsedContent,
  };

  if (name) {
    result.name = name;
  }
  if (description) {
    result.description = description;
  }
  if (category) {
    result.category = category;
  }
  if (addonTemplateId != null) {
    result.addonTemplateId = addonTemplateId;
  }
  if (suggestionId != null) {
    result.suggestionId = suggestionId;
  }

  result.displayType = parsedContent.external ? SURVEY_DISPLAY_TYPES.EXTERNAL : SURVEY_DISPLAY_TYPES.POPUP;

  result.audience = generateAudienceEditorFormat(parsedContent.audience, site);
  result.behavior = generateBehaviorEditorFormat(parsedContent.behavior);
  result.questions = generateQuestionEditorFormat(parsedContent.questions);

  return result;
}

export function validForPreview(errors) {
  return !errors?.theme && !errors?.questions && !errors?.behavior?.feedback;
}

export function hasAtLeastOneQuestion(values) {
  // at least one question, 'thank you' doesn't count
  if (values.questions.length >= 2) return true;
  if (values.questions.length === 1) {
    if (values.questions[0].subtype !== STATIC_DISPLAY_SUBTYPES.THANK_YOU) {
      return true;
    }
  }
  return false;
}

export function showFeedbackButtonInPreview(feedback, isValidForPreview) {
  const buttonExists = document.querySelector(`#${ADDON_PREVIEW_ID} > crazyegg-feedback`);
  const surveyExists = document
    .querySelector(`#${ADDON_PREVIEW_ID} > crazyegg-survey`)
    ?.shadowRoot?.querySelector('#main')?.firstElementChild;

  window.CE2.removeFeedbackButton?.();
  window.CE2.Survey?.update(1, { questionNumber: -1 });

  const show = () =>
    window.CE2.showFeedbackButton?.({
      id: 1,
      preview: true,
      target: `#${ADDON_PREVIEW_ID}`,
      data: {
        behavior: { feedback: { ...feedback, text: replaceEmojiWithImage(feedback.text) } },
        questions: [],
      },
    });

  if (feedback && feedback.cta_bg && isValidForPreview) {
    if (buttonExists || !surveyExists) {
      show();
    } else {
      setTimeout(() => show(), 700);
    }
  }
}

export function removeFeedbackButtonInPreview() {
  window.CE2.removeFeedbackButton?.();
  window.CE2.Survey?.update(1, { questionNumber: 0 });
}

export function getMultiChoiceValueOptions(options) {
  return options.map((x) => ({ value: x }));
}

export function getRatingValueOptions({ subtype, min, max }) {
  let result = [];

  if (subtype === RATING_SUBTYPES.NPS) {
    result = [
      { value: 'promoter', label: '9-10 - Promoter' },
      { value: 'passive', label: '7-8 - Passive' },
      { value: 'detractor', label: '0-6 - Detractor' },
    ];
  } else if (subtype === RATING_SUBTYPES.SMILEY) {
    result = [
      {
        value: 1,
        label: <AnguishedEmojiIcon className="h-4 w-4" aria-label="1" />,
      },
      {
        value: 2,
        label: <SadEmojiIcon className="h-4 w-4" aria-label="2" />,
      },
      {
        value: 3,
        label: <NeutralEmojiIcon className="h-4 w-4" aria-label="3" />,
      },
      {
        value: 4,
        label: <HappyEmojiIcon className="h-4 w-4" aria-label="4" />,
      },
      {
        value: 5,
        label: <GrinningEmojiIcon className="h-4 w-4" aria-label="5" />,
      },
    ];
  } else if (subtype === RATING_SUBTYPES.STARS) {
    result = [1, 2, 3, 4, 5].map((x) => ({
      value: x,
      label: (
        <div className="flex items-center" aria-label={`${x} ${inflect('star', x)}`}>
          {Array.apply(null, { length: x }).map((y, i) => (
            <StarIcon key={i} className="mr-0.5 h-4 w-4 fill-current text-[#916503]" />
          ))}
        </div>
      ),
    }));
  } else if (subtype === RATING_SUBTYPES.NUMERICAL) {
    result = Array.apply(null, new Array(max - min + 1)).map((_, i) => ({
      value: i + min,
    }));
  }
  return result;
}

function getNotCoveredOptions(question) {
  const coveredOptions = [];
  question.logic?.statements.forEach((statement) => {
    statement.conditional.conditions.forEach((condition) => {
      coveredOptions.push(...condition.values);
    });
  });

  let notCoveredOptions = [];

  if (question.type === SURVEY_QUESTION_TYPES.MULTI_CHOICE) {
    notCoveredOptions = getMultiChoiceValueOptions(question.options).filter(
      (option) => !coveredOptions.includes(option.value),
    );
  } else if (question.type === SURVEY_QUESTION_TYPES.RATING) {
    notCoveredOptions = getRatingValueOptions({
      subtype: question.subtype,
      min: question.min.value,
      max: question.max.value,
    }).filter((option) => !coveredOptions.includes(option.value));
  }

  return notCoveredOptions;
}

function getDefaultAction(questions, index) {
  const hasThankyou = questions[questions.length - 1].subtype === STATIC_DISPLAY_SUBTYPES.THANK_YOU;
  const isLast = (index === questions.length - 2 && hasThankyou) || (index === questions.length - 1 && !hasThankyou);

  if (isLast && hasThankyou) {
    return SHOW_QUESTION_OPTIONS.THANK_YOU;
  } else if (isLast && !hasThankyou) {
    return LOGIC_ACTIONS.CLOSE;
  } else {
    return SHOW_QUESTION_OPTIONS.NEXT;
  }
}

export function getDefaultLogicStatement(questions, index) {
  const question = questions[index];

  const option = getNotCoveredOptions(question)?.[0] ?? [];
  const editorAction = getDefaultAction(questions, index);

  return {
    id: uuid(),
    conditional: {
      operator: LOGICALS.AND,
      conditions: [
        {
          criteria: 'answer',
          comparison:
            question.subtype === MULTI_CHOICE_SUBTYPES.MULTI
              ? LOGIC_COMPARISONS.CONTAINS
              : question.subtype === STATIC_DISPLAY_SUBTYPES.NORMAL
                ? LOGIC_COMPARISONS.IS_SUBMITTED
                : LOGIC_COMPARISONS.IS_EXACTLY,
          values: option?.value ? [option.value] : [],
        },
      ],
    },
    actions: [{ editorAction }],
  };
}

export function getLogicStatementsForEveryChoice(questions, index) {
  const question = questions[index];

  const notCoveredOptions = getNotCoveredOptions(question) ?? [];
  const editorAction = getDefaultAction(questions, index);

  const result = notCoveredOptions.map((option) => ({
    id: uuid(),
    conditional: {
      operator: LOGICALS.AND,
      conditions: [
        {
          criteria: 'answer',
          comparison:
            question.subtype === MULTI_CHOICE_SUBTYPES.MULTI
              ? LOGIC_COMPARISONS.CONTAINS
              : LOGIC_COMPARISONS.IS_EXACTLY,
          values: [option.value],
        },
      ],
    },
    actions: [{ editorAction }],
  }));

  if (
    question.optional &&
    !question.logic.statements.find(
      (statement) => statement.conditional.conditions[0].comparison === LOGIC_COMPARISONS.IS_SKIPPED,
    )
  ) {
    result.push({
      id: uuid(),
      conditional: {
        operator: LOGICALS.AND,
        conditions: [
          {
            criteria: 'answer',
            comparison: LOGIC_COMPARISONS.IS_SKIPPED,
            values: [],
          },
        ],
      },
      actions: [{ editorAction }],
    });
  }

  return result;
}
