import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { SkeletonLine } from '@crazyegginc/hatch';

import { usePermissions, useSelectedSite } from '/src/hooks';
import { FEATURES, SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';

import { Section } from '../Section';
import { TrendMetric } from '../TrendMetric';
import { StartTrackingErrorsWall } from '/src/features/errors/components/paywalls/StartTrackingErrorsWall';
import { ERROR_STATUSES, UNIQUE_ERRORS_ORDER } from '/src/features/errors/constants';
import { uniqueErrorListStatsQuery, uniqueErrorListQuery } from '/src/features/errors/queries';

import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';

export function ErrorsTrackingSection({ dateRange }) {
  return (
    <Section className="col-span-1">
      <Section.Header icon={ErrorIcon} color="bg-radical-red-700" to="/errors">
        <span>Errors Tracking</span>
      </Section.Header>
      <Section.Body className="!grow-0">
        <Content dateRange={dateRange} />
      </Section.Body>
    </Section>
  );
}

function Content({ dateRange }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.ERRORS_TRACKING);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.ERRORS_TRACKING} reason={reason} mini={true} />;
  }

  return <ErrorsTrackingContent dateRange={dateRange} />;
}

function ErrorsTrackingContent({ dateRange }) {
  const { selectedSite } = useSelectedSite();

  const { data: statsData, isFetching } = useQuery({
    ...uniqueErrorListStatsQuery({
      siteId: selectedSite?.id,
      startAt: dateRange.start,
      endAt: dateRange.end,
      status: ERROR_STATUSES.UNRESOLVED,
    }),
    enabled: Boolean(selectedSite?.sessionRecording),
  });

  const { data: prevStatsData, isFetching: prevIsFetching } = useQuery({
    ...uniqueErrorListStatsQuery({
      siteId: selectedSite?.id,
      startAt: dateRange.previousStart,
      endAt: dateRange.previousEnd,
      status: ERROR_STATUSES.UNRESOLVED,
    }),
    enabled: Boolean(selectedSite?.sessionRecording),
  });

  const { data, isFetching: listIsFetching } = useInfiniteQuery({
    ...uniqueErrorListQuery({
      siteId: selectedSite?.id,
      limit: 3,
      field: UNIQUE_ERRORS_ORDER.COUNT,
      sort: SORT_ORDER_TYPES.DESC,
      startAt: dateRange.start,
      endAt: dateRange.end,
      status: ERROR_STATUSES.UNRESOLVED,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  const errors = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.uniqueErrorList?.uniqueErrors ?? [])], []) ?? [],
    [data],
  );

  if (selectedSite && !selectedSite.sessionRecording) {
    return <StartTrackingErrorsWall mini={true} />;
  }

  return (
    <>
      <div className="mb-5 flex w-full items-stretch justify-center">
        <TrendMetric
          text="Unique Errors"
          value={statsData?.uniqueErrorListStats.count}
          oldValue={prevStatsData?.uniqueErrorListStats.count}
          below={false}
          className="max-w-[200px]"
          loading={isFetching || prevIsFetching}
          inverseColor={true}
        />
      </div>
      {!listIsFetching && errors.length === 0 ? null : (
        <div className="rounded bg-white-lilac-500 px-4 py-2">
          <div className="text-body-1 mb-2">Most frequent</div>
          {listIsFetching ? (
            <>
              <div className="mb-1 flex h-5 items-center">
                <SkeletonLine className="!w-4/5" />
              </div>
              <div className="mb-1 flex h-5 items-center">
                <SkeletonLine className="!w-3/5" />
              </div>
              <div className="mb-0 flex h-5 items-center">
                <SkeletonLine className="!w-2/3" />
              </div>
            </>
          ) : (
            <>
              {errors.map((e) => (
                <div key={e.fingerprintMd5} className="mb-1 flex last:mb-0">
                  <div className="grow truncate">
                    <Link
                      to={{
                        pathname: `/errors/${e.fingerprintMd5}`,
                        search: `site=${selectedSite.name}`,
                      }}
                      className="text-link truncate leading-5 underline"
                    >
                      {e.fingerprint}
                    </Link>
                  </div>
                  <div className="ml-4 flex w-12 shrink-0 items-center justify-end text-right">
                    {e.count.toLocaleString()}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
}
