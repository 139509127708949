import { assign, sendParent, createMachine, send, actions } from 'xstate';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { normalizeUrl } from '/src/utils/url';
import { VARIANT_STATUSES, abTestTypes } from '/src/features/ab-testing/constants';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { DEVICE_WIDTHS } from '../constants';
import { isNumber } from '/src/utils';
import { findDeviceWidth, findDeviceID } from '/src/features/ab-testing/utils';

import {
  editPage,
  showPage,
  showPreview,
  // refreshPage,
  changeInteractiveMode,
  changeMoveSetting,
  undo,
  redo,
  getVariantColorName,
  changePopupsState,
} from '/src/services/web-editor';

export const createEmptyVariant = (position, editorPatch = {}) => ({
  id: uuid(),
  type: 'variant',
  position,
  status: Object.keys(editorPatch).length > 0 ? VARIANT_STATUSES.DIRTY : VARIANT_STATUSES.PRISTINE,
  editorPatch,
});

export const parseExistingVariant = (variant, type = abTestTypes.PATCH) => {
  if (type === abTestTypes.SPLIT) {
    return {
      id: variant.id,
      type: variant.type === 'CONTROL' ? 'control' : 'variant',
      position: variant.position,
      status:
        variant.type === 'CONTROL'
          ? 'STATIC'
          : isNaN(variant.id) && Object.keys(variant.editorPatch).length > 0
            ? VARIANT_STATUSES.DIRTY
            : VARIANT_STATUSES.PRISTINE,
      redirectUrl: variant.redirectUrl,
      isRetired: variant.isRetired,
      weight: variant.weight,
    };
  }
  return {
    id: variant.id,
    type: variant.type === 'CONTROL' ? 'control' : 'variant',
    position: variant.position,
    status:
      variant.type === 'CONTROL'
        ? 'STATIC'
        : isNaN(variant.id) && Object.keys(variant.editorPatch).length > 0
          ? VARIANT_STATUSES.DIRTY
          : VARIANT_STATUSES.PRISTINE,
    editorPatch: typeof variant.editorPatch === 'string' ? JSON.parse(variant.editorPatch) : variant.editorPatch,
    isRetired: variant.isRetired,
    weight: variant.weight,
  };
};

export const controlVariant = {
  id: uuid(),
  type: 'control',
  position: 0,
  status: 'STATIC',
};

export const initialVariantContext = {
  url: null,
  device: null,
  payload: [controlVariant, createEmptyVariant(1)],
  selectedVariant: controlVariant,
  editor: {
    changes: [],
    interactive: false,
    device: DEVICE_TYPES.DESKTOP,
    deviceId: DEVICE_TYPES.DESKTOP,
    screenWidth: parseInt(DEVICE_WIDTHS[DEVICE_TYPES.DESKTOP], 10),
    undoEnabled: false,
    redoEnabled: false,
    moveSetting: 'reorder',
    loaded: false,
    hidePopups: false,
  },
  draft: null,
};

yup.addMethod(yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value == null || list.some((f) => !!value[f]),
  });
});

export const editorPatchSchema = yup.object().when('type', {
  is: 'variant',
  then: yup
    .object()
    .shape({
      default: yup.array().min(1),
      style: yup.string(),
      script: yup.string(),
    })
    .required()
    .atLeastOneOf(['default', 'style', 'script']),
  otherwise: yup.object().nullable(true),
});

export const variantsSchema = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string().required(),
      type: yup.string().oneOf(['control', 'variant']),
      position: yup.number().required(),
      status: yup.string().when(['id', 'type'], (id, type, schema) => {
        if (type === 'control') {
          return schema.oneOf([VARIANT_STATUSES.DIRTY, VARIANT_STATUSES.STATIC]).required();
        }
        if (isNumber(id)) {
          return schema.oneOf([VARIANT_STATUSES.DIRTY, VARIANT_STATUSES.PRISTINE, VARIANT_STATUSES.DELETED]).required();
        } else if (isNaN(id)) {
          return schema.oneOf([VARIANT_STATUSES.DIRTY]);
        }
      }),
      editorPatch: editorPatchSchema,
    }),
  )
  .min(1)
  .test('at-least-one-non-deleted-variant', 'You must have at least one active variant.', (value) => {
    return value.findIndex((v) => v.status !== VARIANT_STATUSES.DELETED) >= 0;
  })
  .required();

export const variantMachine = createMachine(
  {
    id: 'variantEditor',
    initial: 'active',
    context: {
      ...initialVariantContext,
    },
    predictableActionArguments: true,
    states: {
      active: {
        on: {
          SET: {
            actions: assign({
              url: (ctx, e) => e.value.url,
              matchingUrl: (ctx, e) => e.value.matchingUrl || e.value.url,
              device: (ctx, e) => e.value.device,
            }),
          },
          SET_URL: {
            actions: [
              assign({
                url: (ctx, e) => normalizeUrl(e.url, { appendSlash: false }),
              }),
              actions.pure((ctx, e) =>
                sendParent({
                  type: 'SET_URL',
                  payload: {
                    url: normalizeUrl(e.url, { appendSlash: false }),
                  },
                }),
              ),
            ],
          },
          BACK: {
            target: 'done',
            actions: assign({
              reason: 'BACK',
            }),
          },
          CANCEL: {
            target: 'done',
            actions: assign({
              reason: 'CANCEL',
            }),
          },
          NEXT: {
            target: 'done',
            cond: 'payloadValid',
            actions: assign({
              reason: 'NEXT',
            }),
          },
          ADD_VARIANT: {
            target: 'active',
            actions: [
              assign({
                payload: (ctx) => [...ctx.payload, { ...createEmptyVariant(ctx.payload.length) }],
              }),
              send((ctx) => ({ type: 'SELECT_VARIANT', variant: ctx.payload.at(-1) })),
            ],
          },
          REMOVE_VARIANT: {
            target: 'active',
            actions: [
              actions.pure((ctx, e) => {
                if (ctx.selectedVariant.id === e.value) {
                  const currentIdx = ctx.payload.findIndex((variant) => e.value == variant.id);
                  return send((ctx) => ({ type: 'SELECT_VARIANT', variant: ctx.payload.at(currentIdx - 1) }));
                }
              }),
              assign({
                payload: (ctx, e) => {
                  // if the variant has been saved, mark it for deletion
                  const variantIdx = ctx.payload.findIndex((v) => v.id === e.value);
                  const variant = ctx.payload[variantIdx];

                  if (isNumber(variant.id)) {
                    return [
                      ...ctx.payload.slice(0, variantIdx),
                      {
                        ...variant,
                        status: 'DELETED',
                      },
                      ...ctx.payload.slice(variantIdx + 1),
                    ];
                  } else {
                    return [...ctx.payload.filter((variant) => variant.id !== e.value)];
                  }
                },
              }),
            ],
          },
          SELECT_VARIANT: {
            target: 'active',
            actions: [
              assign({
                selectedVariant: (ctx, e) => {
                  return {
                    ...e.variant,
                  };
                },
                editor: (ctx, e) => ({
                  ...ctx.editor,
                  loaded: false,
                  undoEnabled: (e.variant?.editorPatch?.default ?? []).length > 0,
                  redoEnabled: false,
                }),
              }),
              (ctx) => {
                if (ctx.selectedVariant.type === 'variant' && ctx.selectedVariant.status !== 'STATIC') {
                  editPage(
                    ctx.selectedVariant.id,
                    ctx.selectedVariant.editorPatch,
                    getVariantColorName(ctx.selectedVariant),
                    ctx.editor.moveSetting,
                    ctx.editor.screenWidth,
                  );
                  changeInteractiveMode(false);
                } else if (ctx.selectedVariant.type === 'variant' && ctx.selectedVariant.status === 'STATIC') {
                  showPreview({
                    editorPatch: ctx.selectedVariant.editorPatch,
                    editionId: ctx.selectedVariant.id,
                    editorColor: getVariantColorName(ctx.selectedVariant),
                    screenWidth: ctx.editor.screenWidth,
                  });
                  changeInteractiveMode(false);
                } else if (ctx.selectedVariant.type === 'control') {
                  showPage();
                }
              },
            ],
          },
          LOAD_VARIANT: {
            cond: (ctx) => !ctx.editor.loaded,
            actions: [
              assign({
                editor: (ctx) => ({
                  ...ctx.editor,
                  loaded: true,
                  interactive: ctx.selectedVariant.type === 'control',
                }),
              }),
              (ctx) => {
                if (ctx.selectedVariant.type === 'variant' && ctx.selectedVariant.status !== 'STATIC') {
                  editPage(
                    ctx.selectedVariant.id,
                    ctx.selectedVariant.editorPatch,
                    getVariantColorName(ctx.selectedVariant),
                    ctx.editor.moveSetting,
                    ctx.editor.screenWidth,
                  );
                  changeInteractiveMode(false);
                } else if (ctx.selectedVariant.type === 'variant' && ctx.selectedVariant.status === 'STATIC') {
                  showPreview({
                    editorPatch: ctx.selectedVariant.editorPatch,
                    editionId: ctx.selectedVariant.id,
                    editorColor: getVariantColorName(ctx.selectedVariant),
                    screenWidth: ctx.editor.screenWidth,
                  });
                  changeInteractiveMode(false);
                } else if (ctx.selectedVariant.type === 'control' || ctx.selectedVariant.status === 'STATIC') {
                  showPage();
                }
              },
            ],
          },
          SET_EDITOR: {
            target: 'active',
            actions: [
              assign({
                editor: (ctx, e) => ({
                  ...ctx.editor,
                  [e.key]: e.value,
                }),
              }),
            ],
          },
          UNDO_CHANGE: {
            target: 'active',
            cond: () => {
              return true;
            },
            actions: [() => undo(), assign({ editor: (ctx) => ({ ...ctx.editor, redoEnabled: true }) })],
          },
          REDO_CHANGE: {
            target: 'active',
            cond: () => {
              return true;
            },
            actions: [() => redo(), assign({ editor: (ctx) => ({ ...ctx.editor, undoEnabled: true }) })],
          },
          TOGGLE_INTERACTIVE: {
            actions: [
              assign({
                editor: (ctx) => ({
                  ...ctx.editor,
                  interactive: !ctx.editor.interactive,
                }),
              }),
              (ctx) => {
                changeInteractiveMode(ctx.editor.interactive);
              },
            ],
          },
          CHANGE_POPUPS_STATE: {
            actions: [
              assign({
                editor: (ctx) => ({
                  ...ctx.editor,
                  hidePopups: !ctx.editor.hidePopups,
                }),
              }),
              (ctx) => {
                changePopupsState(ctx.editor.hidePopups);
              },
            ],
          },
          SYNC_MUTATIONS: {
            actions: [
              assign({
                editor: (ctx, e) => ({
                  ...ctx.editor,
                  editorPatch: e.editorPatch,
                  changes: e.editorPatch?.default ?? [],
                  undoEnabled: (e.editorPatch?.default ?? []).length > 0,
                  redoEnabled: false, // latest changes override redo state
                }),
                payload: (ctx, e) =>
                  ctx.payload.map((variant) => {
                    if (variant.id !== ctx.selectedVariant.id) return variant;

                    return {
                      ...variant,
                      status: VARIANT_STATUSES.DIRTY,
                      editorPatch: e.editorPatch,
                    };
                  }),
                selectedVariant: (ctx, e) => ({
                  ...ctx.selectedVariant,
                  status: VARIANT_STATUSES.DIRTY,
                  editorPatch: e.editorPatch,
                }),
              }),
            ],
          },
          SET_MOVE_SETTING: {
            actions: [
              (ctx, e) => changeMoveSetting(e.value),
              assign({
                editor: (ctx, e) => ({
                  ...ctx.editor,
                  moveSetting: e.value,
                }),
              }),
            ],
          },
          SET_DEVICE: {
            actions: [
              assign({
                editor: (ctx, e) => ({
                  ...ctx.editor,
                  device: e.value,
                  deviceId: findDeviceID(e.value),
                  loaded: false,
                  screenWidth: findDeviceWidth(e.value),
                }),
              }),
            ],
          },
          SET_SCREEN_WIDTH: {
            actions: [
              assign({
                editor: (ctx, e) => ({
                  ...ctx.editor,
                  deviceId: e.id,
                  screenWidth: e.value,
                }),
              }),
            ],
          },
          SET_VARIANTS: {
            actions: [
              assign({
                payload: (ctx, e) =>
                  [
                    ...e.payload,
                    e?.createEmptyVariant === true ? createEmptyVariant(e.payload.length, e.initialEditorPatch) : null,
                  ].filter(Boolean),
              }),
              send((ctx) => ({ type: 'SELECT_VARIANT', variant: ctx.payload.at(-1) })),
            ],
          },
        },
      },
      done: {
        type: 'final',
        data: (ctx) => ({
          variants: [...ctx.payload],
          url: ctx.url,
          reason: ctx.reason,
        }),
      },
    },
  },
  {
    guards: {
      payloadValid: (context) => {
        try {
          // exclude pre-existing variants from the validation
          const variants = context.payload.filter((variant) => !['STATIC', 'DELETED'].includes(variant.status));
          variantsSchema.validateSync(variants);
          return true;
        } catch {
          return false;
        }
      },
    },
  },
);
