import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AIBox } from '@crazyegginc/hatch';

import { usePermissions, useSelectedSite, useCrazyAI, useAuthContext, useUserLanguage } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';

import { Section } from '../Section';
import { TrendMetric } from '../TrendMetric';
import { PlayButton } from '/src/components/PlayButton';
import { round } from '/src/utils/math';
import { EnableRecordingsWall } from '/src/features/recordings/components/dashboard/paywalls/EnableRecordingsWall';

import { ReactComponent as RecordingsIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';

export function RecordingsSection({ data, loading }) {
  return (
    <Section className="col-span-1">
      <Section.Header icon={RecordingsIcon} color="bg-wisteria-500" to="/recordings">
        <span>Recordings</span>
      </Section.Header>
      <Section.Body>
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.RECORDINGS);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.RECORDINGS} reason={reason} mini={true} />;
  }

  return <RecordingsContent data={data} loading={loading} />;
}

function RecordingsContent({ data, loading }) {
  const { selectedSite } = useSelectedSite();

  const location = useLocation();
  const { currentUser } = useAuthContext();
  const userPreferredLanguage = useUserLanguage();

  const params = useMemo(
    () => ({
      data: data?.recordings.latestSessions,
      language: userPreferredLanguage,
    }),
    [data, userPreferredLanguage],
  );

  const context = useMemo(
    () => ({
      url: `${location.pathname}${location.search}`,
      userId: currentUser?.userId,
    }),
    [location.pathname, location.search, currentUser?.userId],
  );

  const latestSessionsExist = useMemo(() => {
    if (data?.recordings.latestSessions) {
      return JSON.parse(data.recordings.latestSessions).length > 0;
    }
    return false;
  }, [data]);

  const {
    data: aiData,
    error,
    refetch,
  } = useCrazyAI({
    type: 'recording_suggestions',
    params,
    context,
    enabled: latestSessionsExist,
  });

  const suggestions = useMemo(() => {
    if (aiData?.ready) {
      try {
        const parsed = JSON.parse(aiData.text);
        return {
          ready: true,
          text: (
            <>
              <div className="text-body-1 mb-2 flex items-center">We think you should definitely watch these:</div>
              {parsed.map((x) => (
                <div className="mb-2 flex" key={x.recording_id}>
                  <PlayButton hashedId={x.recording_id} valid={true} sizeClass="h-6 w-6" />
                  <span className="ml-3 leading-tight">{x.reason}</span>
                </div>
              ))}
            </>
          ),
        };
      } catch {
        return { error: true };
      }
    }
    return { ready: false };
  }, [aiData]);

  if (selectedSite && !selectedSite.sessionRecording) {
    return <EnableRecordingsWall mini={true} />;
  }

  return (
    <>
      <div className="mb-5 flex w-full items-stretch justify-center">
        <TrendMetric
          text="Watched"
          extraText={
            <div className="text-center text-base">{`(${
              data?.recordings.total ? round((data.recordings.watched / data.recordings.total) * 100, 1) : ''
            }% of total)`}</div>
          }
          value={data?.recordings.watched}
          className="max-w-[200px]"
          below={false}
          loading={loading}
        />
      </div>
      {!loading && !latestSessionsExist ? null : (
        <AIBox
          id="recording_suggestions"
          data={suggestions}
          error={error || suggestions.error}
          refetch={refetch}
          showChangeLanguage={true}
        />
      )}
    </>
  );
}
