import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, useSelectedSite, usePermissions } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';
import { ErrorsPaywallText } from '/src/features/errors/components/paywalls/ErrorsPaywallText';
import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import ErrorTrackingPNG from '@crazyegginc/hatch/dist/images/illustration-error-tracking.png';
import ErrorTrackingWebP from '@crazyegginc/hatch/dist/images/illustration-error-tracking.webp';

export function StartTrackingErrorsWall({ mini }) {
  const { currentAccount } = useAuthContext();

  const permissions = usePermissions();
  const { selectedSite } = useSelectedSite();
  const canCustomizeRecordings = permissions.can('settings', FEATURES.RECORDINGS).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={ErrorTrackingWebP}
        fallback={ErrorTrackingPNG}
        width={mini ? '80px' : '224px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />

      <Paywall.Title mini={mini}>
        <ErrorsPaywallText.Title />
      </Paywall.Title>
      <Paywall.Body>
        <ErrorsPaywallText.Body />
        {mini ? null : <ErrorsPaywallText.List />}
      </Paywall.Body>

      <div className="flex max-w-xl flex-col items-center rounded border-2 border-mystic-500 p-5">
        <div className="flex items-center justify-between">
          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-solitude-500">
            <RecordingIcon className="h-5 w-5 fill-current text-dodger-blue-500" />
          </div>
          <div className="text-body-2 text-center">
            We can only track errors for recorded visitor sessions, so to get started, you’ll need to enable the
            recordings feature on your website.
          </div>
        </div>
        <div className="mt-5">
          {canCustomizeRecordings ? (
            <Button component={Link} to={`/recordings/settings/sites/${selectedSite?.id ?? ''}`}>
              Enable recordings
            </Button>
          ) : (
            <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
              Contact account owner
            </Button>
          )}
        </div>
      </div>
    </Paywall>
  );
}
