import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SkeletonLine } from '@crazyegginc/hatch';

import { AppConfig } from '/src/AppConfig';

import { Section } from '../Section';
import { TrendMetric } from '../TrendMetric';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';
import { usePermissions, useSelectedSite } from '/src/hooks';
import { FEATURES, SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { snapshotsQuery } from '/src/features/snapshots/queries';
import { SNAPSHOTS_TABLE_ORDER } from '/src/features/snapshots/constants';
import { ReactComponent as SnapshotIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { FirstSnapshotWall } from '/src/features/snapshots/paywalls/FirstSnapshotWall';
import { gettingStartedFeatureKeys } from '/src/features/getting-started/queries';

export function SnapshotsSection({ data, loading }) {
  return (
    <Section className="col-span-1">
      <Section.Header icon={SnapshotIcon} color="bg-lynch-500" to="/snapshots">
        <span>Snapshots</span>
      </Section.Header>
      <Section.Body className="!grow-0">
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.SNAPSHOTS);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.SNAPSHOTS} reason={reason} mini={true} />;
  }

  return <SnapshotsContent data={data} loading={loading} />;
}

function SnapshotsContent({ data, loading }) {
  const { selectedSite } = useSelectedSite();
  const queryClient = useQueryClient();

  const { data: snapshotsData, isLoading } = useQuery({
    ...snapshotsQuery({
      page: 1,
      perPage: 3,
      query: undefined,
      status: undefined,
      folderId: undefined,
      siteId: selectedSite?.id,
      field: SNAPSHOTS_TABLE_ORDER.STARTS_AT,
      sort: SORT_ORDER_TYPES.DESC,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  const snapshots = snapshotsData?.snapshots.list ?? [];

  if (!loading && data?.snapshots.active === 0) {
    return <FirstSnapshotWall mini={true} />;
  }

  return (
    <>
      <div className="mb-5 flex w-full items-stretch justify-center gap-x-3">
        <TrendMetric text="Active" value={data?.snapshots.active} loading={loading} className="max-w-[200px]" />
        {/*<TrendMetric text="Ended in period" value={data?.snapshots.ended} loading={loading} />*/}
      </div>

      <div className="rounded bg-white-lilac-500 px-4 py-2">
        <div className="text-body-1 mb-2">Most recent</div>
        {isLoading ? (
          <>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-4/5" />
            </div>
            <div className="mb-1 flex h-5 items-center">
              <SkeletonLine className="!w-3/5" />
            </div>
            <div className="mb-0 flex h-5 items-center">
              <SkeletonLine className="!w-2/3" />
            </div>
          </>
        ) : (
          <>
            {snapshots.map((s) => (
              <div key={s.id} className="mb-1 grow truncate last:mb-0">
                <a
                  className="text-link truncate"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${AppConfig.legacyCoreBaseURL()}/snapshots/${s.id}}`}
                  onClick={() => {
                    setTimeout(
                      () => queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted }),
                      5000,
                    );
                    return true;
                  }}
                >
                  {s.name}
                </a>
              </div>
            ))}
          </>
        )}
      </div>
      <Section.SeeMore to="/snapshots" />
    </>
  );
}
