import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const homeFeatureKeys = createQueryKeys('homeFeature', {
  dashboard: ({ siteId, startAt, endAt, previousStartAt, previousEndAt }) => [
    {
      siteId,
      startAt,
      endAt,
      previousStartAt,
      previousEndAt,
    },
  ],
});

const metricCompareFragment = gql`
  fragment metricCompare on DashboardMetricCompare {
    current
    previous
  }
`;

export const dashboardQuery = ({ siteId, startAt, endAt, previousStartAt, previousEndAt }) => ({
  ...homeFeatureKeys.dashboard({ siteId, startAt, endAt, previousStartAt, previousEndAt }),
  meta: {
    query: gql`
      query Dashboard($siteId: Int!, $startAt: Int!, $endAt: Int!, $previousStartAt: Int!, $previousEndAt: Int!) {
        dashboard(
          siteId: $siteId
          startAt: $startAt
          endAt: $endAt
          previousStartAt: $previousStartAt
          previousEndAt: $previousEndAt
        ) {
          visitors {
            avgDailyUniqueVisitors {
              ...metricCompare
            }
            avgVisitDuration {
              ...metricCompare
            }
            avgPages {
              ...metricCompare
            }
          }
          abTests {
            active
            totalVisitors
            totalConversions
          }
          addons {
            activeSurveys
            activeCtas
            totalResponses {
              ...metricCompare
            }
            totalClicks {
              ...metricCompare
            }
            topSurveys {
              id
              name
              responses
            }
            topCtas {
              id
              name
              clicks
            }
          }
          goals {
            active {
              ...metricCompare
            }
            totalConversions {
              ...metricCompare
            }
            withConversions {
              ...metricCompare
            }
            topConversions {
              id
              name
              conversions
            }
          }
          recordings {
            watched
            total
            latestSessions
          }
          snapshots {
            active
            ended
          }
        }
      }
      ${metricCompareFragment}
    `,
  },
});
