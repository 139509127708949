import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';
import { Paywall } from '/src/components/Paywall';
import { Button, Tooltip } from '@crazyegginc/hatch';
import { GoalsPaywallText } from '/src/features/goals/paywalls/GoalsPaywallText';

import GoalTargetPNG from '@crazyegginc/hatch/dist/images/illustration-goal-target.png';
import GoalTargetWebP from '@crazyegginc/hatch/dist/images/illustration-goal-target.webp';

export function NoGoalsWall({ mini }) {
  const permissions = usePermissions();
  const canCreateGoals = permissions.can('create', FEATURES.GOALS).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={GoalTargetWebP}
        png={GoalTargetPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />

      <Paywall.Title mini={mini}>
        <GoalsPaywallText.Title />
      </Paywall.Title>
      <Paywall.Body>
        <GoalsPaywallText.Body />
        {mini ? null : <GoalsPaywallText.List />}
      </Paywall.Body>

      <Tooltip show={!canCreateGoals} tooltipContent="You don't have permission to create an A/B Test.">
        <Button
          component={Link}
          to="/goals/new"
          disabled={!canCreateGoals}
          className={classNames('mb-5', { 'pointer-events-none': !canCreateGoals })}
        >
          Create a Goal
        </Button>
      </Tooltip>
    </Paywall>
  );
}
