import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Formik, Form, validateYupSchema, yupToFormErrors, useFormikContext } from 'formik';
import * as yup from 'yup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import { Tab } from '@headlessui/react';
import { Button } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { SEO } from '../../../../components/SEO';
import { DashHeader } from '../../../../components/headers/DashHeader';
import { AddonName } from '../../common/editor/AddonName';
import { SaveAndPublish } from '../../common/editor/SaveAndPublish';
import { Preview } from './Preview';
import { Questions } from './questions/Questions';
import { Appearance } from './Appearance';
import { Audience } from '../../common/editor/Audience';
import { Behavior } from '../../common/editor/Behavior';
import { AddonLoading } from '../../common/AddonLoading';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';
import { BackToDashboardButton } from '../../common/BackToDashboardButton';
import { TemplateFields } from '../../common/editor/TemplateFields';
import { TypeSelector } from './TypeSelector';
import { TabList } from '../../common/editor/TabList';
import { AddonEditorContext } from '../../editor-context';
import { DeletedAddonModal } from '../../common/modals/DeletedAddonModal';

import { SITES_FOR_ADDONS } from '/src/features/addons/queries';
import { DEVICE_TYPES, FEATURES } from '/src/features/_global/constants';

import {
  SURVEY_QUESTION_TYPES,
  TEXT_ENTRY_SUBTYPES,
  RATING_SUBTYPES,
  STATIC_DISPLAY_SUBTYPES,
  RANDOMIZE_OPTIONS,
  BEHAVIOR_TRIGGERS,
  REFERRER_VALUES,
  AUDIENCE_MODES,
  ADDON_TYPES,
  REPEAT_VALUES,
  SURVEY_DISPLAY_TYPES,
} from '/src/features/addons/constants';
import { generateSurveyDataFormat, generateQuestionSurveyFormat } from './editor-functions';

import { useSite, useSelectedSite, useNavBarDimensions, useModal, useAddonScript } from '/src/hooks';
import { getSurveyQueryParams } from '/src/utils/url';
import { mergeDeep, numberOfKeys } from '/src/utils/object';
import { getDocumentCoordinates } from '/src/utils';
import { audienceSchema, templateSchema } from '../../common/editor/validation';
import { useAddonMutations } from '../../common/mutation-functions';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';

const DEFAULT_TITLE = 'Type your question here...';
const DEFAULT_NPS_TITLE = 'How likely are you to recommend us to a friend?';
const DEFAULT_THANK_YOU_TITLE = 'Thank you for your feedback!';
const DEFAULT_EMAIL_TITLE = 'Please enter your email address:';
export const DEFAULT_THANK_YOU_SUBTEXT = "If you have anything else to share, please don't hesitate to contact us.";
export const DEFAULT_NEXT_TITLE = 'Next';
export const DEFAULT_SKIP_TITLE = 'Skip';
export const DEFAULT_MIN_LABEL = 'Terrible';
export const DEFAULT_MAX_LABEL = 'Great';
export const DEFAULT_NPS_MIN_LABEL = 'Not likely';
export const DEFAULT_NPS_MAX_LABEL = 'Very likely';
export const DEFAULT_OPTION = 'Option';
export const DEFAULT_LINK_BUTTON_TITLE = 'Contact Us';
export const DEFAULT_LINK_BUTTON_URL = 'https://www.yoursite.com/contact';
export const DEFAULT_EMAIL_PLACEHOLDER = 'you@email.com';
export const DEFAULT_DROPDOWN_SELECT_TEXT = 'Select answer...';
export const DEFAULT_DROPDOWN_MULTIPLE_ITEMS_TEXT = '# items selected';
export const DEFAULT_IMAGE_ALT = 'Image description';

export const getDefaultErrorMessage = (type, subtype) => {
  switch (type) {
    case SURVEY_QUESTION_TYPES.MULTI_CHOICE:
      return 'Please choose an answer.';
    case SURVEY_QUESTION_TYPES.TEXT_ENTRY: {
      if (subtype === TEXT_ENTRY_SUBTYPES.EMAIL) {
        return 'Please enter a valid email address.';
      }
      return 'Please enter an answer.';
    }
    case SURVEY_QUESTION_TYPES.RATING:
      return 'Please choose a rating.';
  }
};

export const getDefaultTitle = (subtype) => {
  switch (subtype) {
    case RATING_SUBTYPES.NPS:
      return DEFAULT_NPS_TITLE;
    case STATIC_DISPLAY_SUBTYPES.THANK_YOU:
      return DEFAULT_THANK_YOU_TITLE;
    case TEXT_ENTRY_SUBTYPES.EMAIL:
      return DEFAULT_EMAIL_TITLE;
    default:
      return DEFAULT_TITLE;
  }
};

const getDefaultQuestion = (type, subtype) => ({
  id: uuid(),
  type,
  subtype,
  title: getDefaultTitle(subtype),
  subtext: subtype === STATIC_DISPLAY_SUBTYPES.THANK_YOU ? DEFAULT_THANK_YOU_SUBTEXT : '',
  optional: subtype === STATIC_DISPLAY_SUBTYPES.THANK_YOU ? true : false,
  nextButton: {
    title: DEFAULT_NEXT_TITLE,
    enabled: false,
  },
  skipButton: {
    title: DEFAULT_SKIP_TITLE,
  },
  options: [`${DEFAULT_OPTION} 1`, `${DEFAULT_OPTION} 2`],
  randomize: RANDOMIZE_OPTIONS.NONE,
  placeholder: subtype === TEXT_ENTRY_SUBTYPES.EMAIL ? DEFAULT_EMAIL_PLACEHOLDER : '',
  min: {
    value: 1,
    label: subtype === RATING_SUBTYPES.NPS ? DEFAULT_NPS_MIN_LABEL : DEFAULT_MIN_LABEL,
  },
  max: {
    value: 5,
    label: subtype === RATING_SUBTYPES.NPS ? DEFAULT_NPS_MAX_LABEL : DEFAULT_MAX_LABEL,
  },
  linkButton: {
    enabled: false,
    title: DEFAULT_LINK_BUTTON_TITLE,
    url: DEFAULT_LINK_BUTTON_URL,
  },
  requiredErrorMessage: getDefaultErrorMessage(type, subtype),
  logic: {
    version: 1,
    statements: [],
  },
  dropdownSelect: {
    defaultText: DEFAULT_DROPDOWN_SELECT_TEXT,
    multipleItemsText: DEFAULT_DROPDOWN_MULTIPLE_ITEMS_TEXT,
  },
});

export function generateInitialQuestionSchema(type, subtype) {
  return { ...window.CE2.Survey.castToSchema(getDefaultQuestion(type, subtype)) };
}

const surveySchema = yup.object().shape({
  name: yup
    .string()
    .required('Please provide a name for the survey.')
    .matches(/^(?!\s+$).*/, 'The name cannot contain only spaces.'),
  mode: yup.string().oneOf([...Object.values(AUDIENCE_MODES)], 'Please select a mode.'),
  displayType: yup.string().oneOf([...Object.values(SURVEY_DISPLAY_TYPES)], 'Please select a type.'),
  audience: yup
    .object()
    .when(['mode', 'displayType'], (mode, displayType, schema) =>
      mode === AUDIENCE_MODES.SIMPLE && displayType === SURVEY_DISPLAY_TYPES.POPUP
        ? schema.shape({}).concat(audienceSchema)
        : schema.shape({}),
    ),
  sampling: yup
    .number()
    .min(1, 'Sampling must be at least ${min}%. Please provide a larger number.')
    .max(100, 'Sampling cannot exceed ${max}. Please provide a lower number.'),
  behavior: yup.object().when(['mode', 'displayType'], (mode, displayType, schema) => {
    if (displayType === SURVEY_DISPLAY_TYPES.EXTERNAL) return schema.shape({});

    let launch = yup.object().shape({});
    if (mode === AUDIENCE_MODES.SIMPLE) {
      launch = yup.object().shape({
        show: yup.string().oneOf([...Object.values(BEHAVIOR_TRIGGERS)]),
        timeDelaySeconds: yup.number().when('show', {
          is: (value) => value === BEHAVIOR_TRIGGERS.TIME_DELAY,
          then: yup
            .number()
            .required('Please provide a valid number for seconds.')
            .typeError('Please provide a valid number for seconds.')
            .integer('Please provide a valid number for seconds.')
            .positive('Please provide a valid number for seconds.')
            .min(1, 'Seconds should be at least ${min}. Please provide a larger number.')
            .max(600, 'Seconds cannot exceed ${max}. Please provide a lower number.'),
        }),
        numPages: yup.number().when('show', {
          is: (value) => value === BEHAVIOR_TRIGGERS.NUM_PAGES,
          then: yup
            .number()
            .required('Please provide a valid number for pages.')
            .typeError('Please provide a valid number for pages.')
            .integer('Please provide a valid number for pages.')
            .positive('Please provide a valid number for pages.')
            .min(1, 'Pages should be at least ${min}. Please provide a larger number.'),
        }),
        feedback: yup.object().when('show', {
          is: (value) => value === BEHAVIOR_TRIGGERS.FEEDBACK,
          then: yup.object().shape({
            text: yup
              .string()
              .required('Please provide a text for the button.')
              .max(30, 'The button text should be less than ${max} chars.'),
            cta_bg: yup.string(),
            cta_bg_hover: yup.string(),
            cta_bg_active: yup.string(),
            cta_text: yup.string(),
            focus: yup.string(),
          }),
        }),
      });
    }
    return schema
      .shape({
        repeat: yup.string().oneOf([...Object.values(REPEAT_VALUES)]),
        repeatCount: yup.number().when('repeat', {
          is: (value) => value === REPEAT_VALUES.NUMBER,
          then: yup
            .number()
            .required('Please provide a number for the repeat count.')
            .typeError('Please provide a valid number for the repeat count.')
            .integer('Please provide a valid number for the repeat count.')
            .positive('Please provide a valid number for the repeat count.')
            .min(1, 'Repeat count should be at least ${min}. Please provide a larger number.'),
        }),
      })
      .concat(launch);
  }),
});

const initialData = (isTemplate) => {
  const data = {
    name: 'New Survey ' + format(new Date(), 'yyyy-MM-dd HH:mm'),
    questions: [generateInitialQuestionSchema(SURVEY_QUESTION_TYPES.STATIC_DISPLAY, STATIC_DISPLAY_SUBTYPES.THANK_YOU)],
    theme: {
      dark: false,
      accent: '#00A0BC',
      removeBranding: false,
      customBrandingText: '',
      adjustColors: true,
    },
    displayType: SURVEY_DISPLAY_TYPES.POPUP,
    position: 'bottomRight',
    autoPosition: true,
    autoPositionFallbackToShow: true,
    mode: AUDIENCE_MODES.SIMPLE,
    closable: false,
    audience: {
      device: [DEVICE_TYPES.DESKTOP, DEVICE_TYPES.TABLET, DEVICE_TYPES.MOBILE],
      allPages: true,
      referrerOption: REFERRER_VALUES.EVERYONE,
    },
    sampling: 100,
    behavior: {
      show: BEHAVIOR_TRIGGERS.IMMEDIATE,
      timeDelaySeconds: 30,
      numPages: 3,
      feedback: {
        text: 'Feedback',
      },
      repeat: REPEAT_VALUES.SUBMIT,
      repeatCount: 1,
    },
  };

  if (isTemplate) {
    data.name = 'New Template ' + format(new Date(), 'yyyy-MM-dd HH:mm');
    data.description = '';
    data.category = '';
    data.icon = 'star';
  }

  return data;
};

export function SurveyEditor({
  id,
  initData,
  isTemplate = false,
  readonly = false,
  publishedEdit = false,
  publishedQuestionIds,
}) {
  const stableInitData = useRef(initData);
  const [surveyReady, setSurveyReady] = useState(false);
  useAddonScript(
    useCallback(() => setSurveyReady(true), []),
    'survey',
  );
  const { initialNavBarHeight } = useNavBarDimensions();
  const saveAndPublishComp = useRef();
  const modal = useModal();

  const handleAddOnsCapabilities = useCallback((addonsCapabilities) => {
    setEditorState((state) => ({ ...state, addonsCapabilities }));
  }, []);

  const [editorState, setEditorState] = useState({
    id,
    isTemplate,
    readonly,
    publishedEdit,
    publishedQuestionIds,
    type: ADDON_TYPES.SURVEY,
    spelledType: 'survey',
    addonsCapabilities: {},
    handleAddOnsCapabilities,
  });
  const { createAddon, editAddon, createTemplate, editTemplate } = useAddonMutations();

  const { selectedSite, selectSite } = useSite({
    sitesQuery: SITES_FOR_ADDONS,
    dontSelectSite: !!stableInitData.current?.audience?.site?.id, //we'll select the site from initData if it's set
  });
  const editorRef = useRef();
  const [yCoord, setYCoord] = useState(null);

  const getCoordiantes = useCallback((node) => {
    if (node !== null) {
      setYCoord(getDocumentCoordinates(node).top);
    }
  }, []);

  // if initdata has site already, select that initially
  useEffect(() => {
    if (!selectedSite?.id && stableInitData.current?.audience?.site?.id) {
      selectSite(stableInitData.current.audience.site);
    }
  }, [selectedSite, selectSite]);

  // this is used for saving, auto-saving and as a first step to pubishing
  async function handleFormSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    let returnValue = false;

    const survey = generateSurveyDataFormat({ values, isTemplate, id: editorState.id, publishedEdit });

    if (isTemplate) {
      if (editorState.id) {
        returnValue = await editTemplate(survey, values.thumbnail);
      } else {
        const result = await createTemplate(survey, values.thumbnail);
        if (result) {
          setEditorState((state) => ({ ...state, id: result }));
          returnValue = result;
        }
      }
    } else {
      if (editorState.id) {
        returnValue = await editAddon(survey);
        if (returnValue.status === 'DELETED') {
          await new Promise((resolve) => {
            modal.show(
              <DeletedAddonModal
                type={editorState.spelledType}
                onConfirm={async () => {
                  const survey = generateSurveyDataFormat({ values, isTemplate });
                  const result = await createAddon(survey, values.audience.site.id);
                  if (result) {
                    const { id, previewToken } = result;
                    setEditorState((state) => ({ ...state, id }));
                    returnValue = { id, previewToken };
                    resolve();
                  }
                }}
              />,
            );
          });
        }
      } else {
        const result = await createAddon(survey, values.audience.site.id);
        if (result) {
          const { id, previewToken } = result;
          setEditorState((state) => ({ ...state, id }));
          returnValue = { id, previewToken };
        }
      }
    }
    setSubmitting(false);
    return returnValue;
  }

  if (!surveyReady || !selectedSite?.id || !initialNavBarHeight) {
    return <AddonLoading type={FEATURES.SURVEYS} />;
  }

  let validationSchema = window.CE2.Survey.getValidationSchema().concat(surveySchema);
  if (isTemplate) {
    validationSchema = validationSchema.concat(templateSchema);
  }

  return (
    <AddonEditorContext.Provider value={editorState}>
      <Formik
        initialValues={mergeDeep(initialData(isTemplate), initData)}
        validate={async (values) => {
          return validateYupSchema(
            { ...values, questions: generateQuestionSurveyFormat(values.questions) },
            validationSchema,
          ).then(
            () => ({}),
            (err) => yupToFormErrors(err),
          );
        }}
        onSubmit={handleFormSubmit}
      >
        {({ values }) => (
          <DashboardPage mainClass="!pb-0">
            <SEO title="Surveys" />
            <Form>
              <DashHeader
                titleComponent={
                  <Breadcrumb>
                    <BreadcrumbItem
                      active={false}
                      to={{
                        pathname: `/addons`,
                        search: `type=${FEATURES.SURVEYS}${getSurveyQueryParams() ? `&${getSurveyQueryParams()}` : ''}`,
                      }}
                    >
                      Surveys
                    </BreadcrumbItem>
                    <BreadcrumbItem active={true}>{values.name}</BreadcrumbItem>
                  </Breadcrumb>
                }
                actionButton={
                  <div className="flex items-center space-x-2.5">
                    <BackToDashboardButton type={FEATURES.SURVEYS} />
                    {!readonly && <SaveAndPublish ref={saveAndPublishComp} />}
                  </div>
                }
              />
              <DndProvider backend={HTML5Backend}>
                <div className="flex" ref={getCoordiantes}>
                  <section
                    style={{ height: `calc(max(100vh, ${initialNavBarHeight}px) - ${yCoord}px)` }}
                    className={classNames(
                      'min-w-min flex-grow pb-20 pr-5 pt-8',
                      'border-r border-mystic-500 bg-white shadow-md',
                      '!overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded',
                    )}
                    ref={editorRef}
                  >
                    <AddonName />
                    {isTemplate && <TemplateFields />}
                    <TypeSelector />
                    <TabsSection editorRef={editorRef} />
                  </section>

                  <Preview saveSurvey={() => saveAndPublishComp.current.saveAddon()} />
                </div>
              </DndProvider>
            </Form>
          </DashboardPage>
        )}
      </Formik>
    </AddonEditorContext.Provider>
  );
}

const tabs = ['Questions', 'Settings'];

function TabsSection({ editorRef }) {
  const { selectedSite } = useSelectedSite();
  const { state: locationState } = useLocation();
  const [selectedTab, setSelectedTab] = useState(locationState?.goToSampling ? 1 : 0);
  const { errors, touched, values, setFieldValue } = useFormikContext();

  // store selected site initially if nothing set yet
  useEffect(() => {
    if (!values.audience.site) {
      setFieldValue('audience.site', selectedSite);
    }
  }, [setFieldValue, selectedSite, values.audience.site]);

  const hasTabError = (header) => {
    if (header === 'Questions') {
      return errors.questions;
    } else if (header === 'Settings') {
      let errorCount = numberOfKeys(errors?.theme) + numberOfKeys(errors?.audience) + numberOfKeys(errors?.behavior);
      // don't count untouched input fields as errors
      if (!touched?.audience?.referrers && errors?.audience?.referrers) {
        errorCount -= 1;
      }
      if (!touched?.audience?.pages && errors?.audience?.pages) {
        errorCount -= 1;
      }
      return errorCount > 0;
    }
  };

  const goToTab = (name) => {
    const tabNumber = tabs.findIndex((t) => t === name);
    if (tabNumber > -1) {
      setSelectedTab(tabNumber);
      editorRef.current.scrollTo(0, 0);
    }
  };

  return (
    <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
      <TabList tabs={tabs} hasTabError={hasTabError} />

      <Tab.Panels>
        <Tab.Panel>
          <Questions />
          <div className="mt-10 flex w-full justify-end">
            <Button size="lg" onClick={() => goToTab('Settings')}>
              Settings <ArrowIcon className="ml-2.5 h-3.5 w-3.5 fill-current" />
            </Button>
          </div>
        </Tab.Panel>

        <Tab.Panel>
          <Audience />
          <Appearance />
          <Behavior />
          <div className="mt-10 w-full pl-10">
            <Button size="lg" onClick={() => goToTab('Questions')}>
              <ArrowIcon className="mr-2.5 h-3.5 w-3.5 rotate-180 fill-current" />
              Survey questions
            </Button>
          </div>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}
